@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 1px;
    line-height: 1.78;

}

body {
    font-family: "Poppins";
    background-color: #f9f9f9;
    color: rgb(90, 90, 90);
}

a {
    text-decoration: none;
    color: inherit;
    transition: 0.5s all ease;
}

.row {
    margin: 0;
}

.underline {
    width: 100px;
    height: 0;
    border-bottom: 3px solid black;
    margin: 0 auto;
}

.no-select {
    -webkit-user-select: none;
    /* Chrome, Safari, Opera */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Standard */
}