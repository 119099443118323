.productPacking {


    img {
        max-width: 220px;
    }


    li::marker {
        color: green;
    }

    

}