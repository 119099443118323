.about {

    .companyImg {
        img {
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 16px;
        }

    }
}