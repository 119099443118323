footer {
    .footer-icon {
        width: 24px;
        height: 24px;
    }

    .copy {
        font-size: x-small;
    }

    .logo {
        width: 180px;
        filter: invert(1);
    }
}