@import "../../styles/global.scss";

header {
    //overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 200;
    transition: 0.5s all ease !important;
    background-color: white !important;
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);

    .logo {
        width: 120px;
    }

    .lista {
        min-height: 80px;
        transition: 0.5s all ease !important;
    }

    img {
        width: 255px;
        transition: 0.5s all ease !important;
    }

    a {
        position: relative;
        color: rgb(45, 45, 45);
        padding: 5px;

    }

    .header-icon {
        img {
            width: 24px;

            &:hover {
                filter: brightness(0) saturate(100%) invert(30%) sepia(100%) hue-rotate(70deg) saturate(300%) brightness(120%);
            }


        }
    }

    nav {
        transition: 0.5s all ease !important;


        .a {
            transition: 0.5s all ease !important;
            border-bottom: 3px solid transparent !important;


        }

        .a:hover {
            border-bottom: 3px solid green !important;


        }

        .a.active {
            border-bottom: 3px solid rgb(3, 162, 24) !important;

        }


    }


}

@media (max-width: 992px) {
    nav {
        .a {
            font-size: 20px;
            text-align: center;
            border-radius: 8px;

            &:hover {
                background-color: green;
                color: white !important;
            }

            &.active {
                background-color: green !important;
                color: white !important;
            }
        }


    }
}

.scroll {
    transition: 0.5s all ease !important;

    img {
        width: 180px;

    }

    .logo {
        width: 80px !important;
    }

    .lista {
        min-height: 60px;
    }

}

.test {
    background-color: #ffffff00 !important;
}

.megaMenu-content {
    display: none;
    position: absolute;
    //
    width: 50%;
    left: 50%;
    top: 200px;
    transform: translate(-50%, -50%);
    //box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 20;
    transition: ease-in-out 2s;
    border-radius: 8px;
}

.megaMenu-item {
    background-color: #ffffff;

    img {
        width: 130px;
        transition: transform 0.8s ease
    }

    img:hover {
        transform: scale(1.1);
    }
}

.megaMenu:hover .megaMenu-content {
    display: block;
    border-radius: 8px;
}

@media screen and (max-width: 600px) {
    .megaMenu-column {
        width: 100%;
        height: auto;
    }
}