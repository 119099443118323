.accordion {
    position: relative;

    .accordion-body {
        width: 98%;
        margin: auto;
        font-size: small;
    }

    button {
        background-color: rgb(245, 245, 245);
        
    }
}