.table {
    span {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: rgb(16, 167, 16);
        border-radius: 50%;
        margin-right: 5px;
    }

    th {
        color: rgb(0, 199, 0) !important;
        font-weight: 500;
    }

    td {
        min-width: 120px;
    }
}