.productVariation {
    img {
        width: 200px;
        transition: transform 0.8s ease
    }

    ;

    img:hover {
        transform: scale(1.1);
    }

    @media (max-width: 1300px) {
        img {

            width: 100%;
            /* Make the image take the full width of the container */
            height: 100%;
            /* Make the image take the full height of the container */
            object-fit: cover;
            /* Scale the image to cover the container */
            object-position: center;
            /* Center the image */
        }
    }


}