.carousel {
    min-height: 30vh;
    position: relative;

    .carouselImage {
        &::after {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.118);
            z-index: -1;
        }
        
    }

    img {
        position: absolute;
        height: 30vh;
        top: 0;
        object-fit: cover;
        object-position: center;
        width: 100%;
        z-index: -1;

    }


   

}