$primary: #29a500;
//$primary:#9fb50c;
$secondary: #2a3447;


@import "../../node_modules/bootstrap/scss/bootstrap";

//h1-h6

h1,
h2,
h3,
h4,
h5 {
    padding: 0;
    margin: 0;
}


h2.underlined-center {
    position: relative;
    margin-bottom: 1em;
  }
  
  h2.underlined-center::after {
    content: "";
    position: absolute;
    top: 100%;
    margin-top: 0.375em;
    height: 4px;
    width: 4rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background-color: $primary;
  }
  
  h2.underlined-left {
    position: relative;
    margin-bottom: 1em;
  }
  
  h2.underlined-left::after {
    content: "";
      position: absolute;
      top: 100%;
      margin-top: 0.375em;
      height: 4px;
      width: 5rem;
      left: 0em;
      background-color: $primary;
  }