.contact {
    img {
        width: 35px;
        filter: invert(1);
    }
    @media (max-width: 768px) {
        .contactForm {
            /* Smaller size for mobile devices */
            display: flex;
            flex-direction: column;
            .info {
                border-top-right-radius: 18px;
                border-bottom-left-radius: 0px;

                &::after {
                    width: 200px;
                    height: 200px;
                }
            }
        }
    }
    @media (max-width: 500px) {
        .contactForm {
            .info {


                &::after {
                    width: 160px;
                    height: 160px;
                }
            }
        }
    }

    .info {
        position: relative;
        border-top-left-radius: 18px;
        border-bottom-left-radius: 18px;
        text-align: justify ;
        &::after {
            content: '';
            position: absolute;
        
            right: 0;
            bottom: 0;
            width: 250px;
            height: 250px;
            background-image: url("../../../public/media/leaves.png");
            background-repeat: no-repeat;
            filter: invert(1);
        }
    }
}