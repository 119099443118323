.parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;

    img {
        display: block;
        width: 100% !important;
        /* Make the image take the full width of the container */
        height: 100% !important;
        /* Make the image take the full height of the container */
        object-fit: cover !important;
        /* Scale the image to cover the container */
        object-position: center !important;
        /* Center the image */
        border-radius: 8px;
    }
}

.div1 {
    grid-area: 1 / 1 / 3 / 3;
}

.div2 {
    grid-area: 1 / 3 / 2 / 4;
}

.div3 {
    grid-area: 2 / 3 / 3 / 4;

}

.div4 {
    grid-area: 1 / 4 / 3 / 5;
}

.div5 {
    grid-area: 3 / 1 / 4 / 2;
}

.div6 {
    grid-area: 3 / 2 / 4 / 3;
}

.div7 {
    grid-area: 3 / 3 / 4 / 4;
}

.div8 {
    grid-area: 3 / 4 / 4 / 5;
}