.infiniteScroll {
    background-image: url('../../../public/media/dark-moss.jpg');
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
}


.wrapper {
    pointer-events: none;
    user-select: none;
    width: 100%;
    max-width: 1536px;
    margin-inline: auto;
    position: relative;
    height: 90px;
    margin-top: 5rem;
    overflow: hidden;
    mask-image: linear-gradient(to right,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 1) 20%,
            rgba(0, 0, 0, 1) 80%,
            rgba(0, 0, 0, 0));

    .item {
        width: 250px;
        height: 90px;
        background-color: rgba(0, 0, 0, 0.077);
        border-radius: 16px;
        position: absolute;
        left: max(calc(250px * 8), 100%);
        animation-name: scrollLeft;
        animation-duration: 30s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        display: flex;
        align-items: center;
        gap: 16px;
        font-weight: bold;
        color: rgb(255, 255, 255);
        padding: 6px;

        img {
            background-color: rgb(0, 171, 0);
            padding: 5px;
            border-radius: 50%;
        }
    }



    @keyframes scrollLeft {
        to {
            left: -250px;
        }
    }

}