.aboutSection {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    min-height: 70vh;
    grid-gap: 2em;
    justify-content: center;

    .product {
        border-radius: 18px;
        transition: ease-in-out 0.2s;
        cursor: pointer;


        img {
            width: 180px;

        }

        &:hover {
            box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.1), -10px -10px 10px white;
        }
    }

    .parent {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 12px;
        grid-row-gap: 12px;

        img {
            width: 100%;
            /* Make the image take the full width of the container */
            height: 100%;
            /* Make the image take the full height of the container */
            object-fit: cover;
            /* Scale the image to cover the container */
            object-position: center;
            /* Center the image */
            border-radius: 16px;
        }
    }

    .div1 {
        grid-area: 1 / 1 / 2 / 2;
    }

    .div2 {
        grid-area: 1 / 2 / 2 / 3;
    }

    .div3 {
        grid-area: 1 / 3 / 2 / 4;
    }


}

@media screen and (max-width: 1008px) {
    .aboutSection {
        grid-template-columns: 1fr;
        /* Skryje první sloupec a ponechá pouze druhý */

       

    }

    .aboutSection>*:first-child {
        display: none;
        /* Skryje první prvek (první sloupec) */
    }
}