.productIconPropertyBig {
    background-image: url('../../../public/media/dark-moss.jpg');
    background-size: cover; /* Adjust as needed */
    background-position: center; /* Adjust as needed */

    img {
        width: 60px;
        height: 60px;
        padding: 8px;
        background-color: rgb(0, 171, 0);
        border-radius: 50%;
    }


}

@media (max-width: 767.98px) {
    /* CSS pro střední a menší obrazovky */
    .productIconPropertyBig {
      img {
        width: 50px;
        height: 50px;
        padding: 6px;
      }
    }
  }