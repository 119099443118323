.faq {

    .accordionBox {
        position: relative;
        overflow: hidden;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 18px;
        background-color: rgb(0, 166, 20);
    }

    .accordion {


        position: relative;
    }

    .contactIcons {
        img {
            background-color: rgb(234, 234, 234);
            border-radius: 16px;
            transition: 0.2s ease-in-out;

            &:hover {
                background-color: rgb(255, 255, 255);
            }


        }



    }
}


@media (max-width: 608px) {
    .contactIcons {
        img {
            width: 60px;
        }
    }

}