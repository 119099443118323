.bolmos {
    .colourBox {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 12px;
        grid-row-gap: 12px;

        .item-1 {
            grid-area: 1 / 1 / 1 / 2;
        }
        .item-2 {
            grid-area: 1 / 2 / 1 / 3;
        }
        .item-3 {
            grid-area: 1 / 3 / 1 / 4;
        }

    }
}