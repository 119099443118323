.carouselMain {
    .mainHeading {
        position: absolute;
        top: 40%;
        left: 15%;
        z-index: 100;
    }
    img {
        position: relative;
        height: 100vh;
        object-fit: cover;
        object-position: center;

    }

    .carousel-item {
        &::after {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.118);
            z-index: 5454545;
        }
    }
    
}